:root {
  /* COLORS */
  --color-border: rgba(0, 0, 0, 0.25);
  --color-button-hover: salmon;
  --color-header: #333;
  --color-link: #88e;
  --color-primary: #6dc7be;
  --color-accent: #f58a1f;
  --color-accent-200: #f4f8f8;
  --color-accent-300: #c9eeed;
  --color-accent-400: #ade2e0;
  --white: white;
  --black: #000;
  --grey-50: #f4f5f8;
  --grey-100: #f2f5f6;
  --grey-200: #c7ccd8;
  --grey-300: #7a7a7a;
  --green-200: #00a4b833;
  --green-300: #80dad1;
  --green-400: #6dc7be;
  --green-500: #5a9d95;
  --green-900: #48756f;
  --blue-900: #1b1b46;
  --blue-800: #23527a;
  --blue-700: #004e70;
  --blue-200: #e6f0f3;
  --blue-100: #f2f7f9;
  --red-700: #e83c48;
  --red-800: #c72530;
  --red-900: #b21c27;
  --pink-400: #d12d91;

  /* FONTS */
  --font-body: 'Quicksand', sans-serif;
  --font-header: 'navigo', serif;

  /* SPACING */
  --spacing-base: 0.25rem;
  --spacing-0-5: calc(var(--spacing-base) / 2);
  --spacing-1: calc(var(--spacing-base) * 1);
  --spacing-1-5: calc(var(--spacing-base) * 1.5);
  --spacing-2: calc(var(--spacing-base) * 2);
  --spacing-3: calc(var(--spacing-base) * 3);
  --spacing-4: calc(var(--spacing-base) * 4); /* 1rem */
  --spacing-5: calc(var(--spacing-base) * 5);
  --spacing-6: calc(var(--spacing-base) * 6);
  --spacing-7: calc(var(--spacing-base) * 7);
  --spacing-8: calc(var(--spacing-base) * 8); /* 2rem */
  --spacing-9: calc(var(--spacing-base) * 9);
  --spacing-10: calc(var(--spacing-base) * 10);
  --spacing-11: calc(var(--spacing-base) * 11);
  --spacing-12: calc(var(--spacing-base) * 12); /* 3rem */
  --spacing-13: calc(var(--spacing-base) * 13);
  --spacing-14: calc(var(--spacing-base) * 14);
  --spacing-15: calc(var(--spacing-base) * 15);
  --spacing-16: calc(var(--spacing-base) * 16); /* 4rem */
  --spacing-17: calc(var(--spacing-base) * 17);
  --spacing-18: calc(var(--spacing-base) * 18);
  --spacing-19: calc(var(--spacing-base) * 19);
  --spacing-20: calc(var(--spacing-base) * 20); /* 5rem */
  --spacing-22: calc(var(--spacing-base) * 22);
  --spacing-23: calc(var(--spacing-base) * 23);
  --spacing-24: calc(var(--spacing-base) * 24); /* 6rem */
  --spacing-26: calc(var(--spacing-base) * 26);
  --spacing-28: calc(var(--spacing-base) * 28);
  --spacing-32: calc(var(--spacing-base) * 32); /* 8rem */
  --spacing-34: calc(var(--spacing-base) * 34);
  --spacing-38: calc(var(--spacing-base) * 38);
  --spacing-40: calc(var(--spacing-base) * 40); /* 10rem */
  --spacing-48: calc(var(--spacing-base) * 48); /* 11rem */
  --spacing-52: calc(var(--spacing-base) * 52); /* 13rem */
  --spacing-60: calc(var(--spacing-base) * 60); /* 15rem */
  --spacing-64: calc(var(--spacing-base) * 64); /* 16rem */
  --spacing-72: calc(var(--spacing-base) * 72); /* 18rem */
  --spacing-80: calc(var(--spacing-base) * 80); /* 20rem */
  --spacing-88: calc(var(--spacing-base) * 88); /* 22rem */
  --spacing-96: calc(var(--spacing-base) * 96); /* 24rem */
  --spacing-104: calc(var(--spacing-base) * 104); /* 25rem */
  --spacing-112: calc(var(--spacing-base) * 112); /* 26rem */

  /* TYPOGRAPHY */
  --fs-base: 62.5%;
  --fs-normal: 1.6rem;
  --header-weight: bold;
  --header-lh: 1.5;
  --header-ls: 0.25rem;
  --header-align: center;
  --h1-size: 6rem;
  --h2-size: 4rem;
  --h3-size: 2rem;
  --h4-size: 1.8rem;
  --lh-normal: 1.4;
  --ls-normal: 0.02rem;
  --p-size: 1.6rem;
  --p-lh: 1.4;
  --p-ls: 0.2rem;
  --p-mb: var(--spacing-2);

  /* BORDERS */
  --border: 1px solid var(--color-border);
  --radius: 4px;
  --radius-sm: 2px;
  --radius-md: 6px;
  --radius-lg: 10px;

  /* SHADOWS */
  --box-shadow: 0 2px 1px -1px rgba(0, 0, 0, 0.2), 0 1px 1px 0 rgba(0, 0, 0, 0.14), 0 1px 3px 0 rgba(0, 0, 0, 0.12);

  /* CONTAINERS */
  --container-width-xs: 600px;
  --container-width-sm: 800px;
  --container-width: 1200px;
  --container-lg: 1550px;
  --container-xl: 2454px;

  --g-color-disabled: #c9d0d2;
}

/* Buttons */
.c-button {
  align-items: center;
  display: inline-flex;
  justify-content: center;
  border: 2px solid;
  border-radius: 10px;
  font-weight: 700;
  padding: .5rem 1rem;
}

.c-button:disabled {
  background-color: #bed1d8;
  border-color: #bed1d8;
  pointer-events: none;
}

.c-button--primary {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  color: var(--g-color-white);
}

.c-button--primary:focus, .c-button--primary:hover:enabled {
  background-color: var(--color-primary);
  border: 2px solid;
  border-color: var(--color-primary);
  box-shadow: none;
}

.c-button--secondary {
  background-color: var(--green-900);
  border-color: var(--green-900);
  color: var(--g-color-white);
}

.c-button--secondary:focus, .c-button--secondary:hover:enabled {
  background-color: var(--green-900);
  border: 2px solid;
  border-color: var(--green-900);
  box-shadow: none;
}

.c-button--tertiary {
  background-color: transparent;
  border-color: var(--g-color-white);
  color: var(--g-color-white);
}

.c-button--tertiary:focus, .c-button--tertiary:hover:enabled {
  background-color: transparent;
  border: 2px solid;
  border-color: var(--g-color-white);
  box-shadow: none;
}

.c-button--cta--tertiary {
  background-color: var(--g-color-white);
  border-color: var(--color-accent);
  color: var(--color-accent);
}

.c-button--cta--tertiary:focus, .c-button--cta--tertiary:hover:enabled {
  background-color: var(--g-color-white);
  border: 2px solid;
  border-color: var(--color-accent);
  box-shadow: none;
}

.c-button__text {
  justify-content: center;
}

.c-button__text span {
  margin-bottom: 0;
}

.c-button--primary:focus {
  box-shadow: 0 0 0 var(--g-spacing-3x-small) var(--color-primary), inset 0 0 0 var(--g-spacing-3x-small) var(--g-color-white);
}

/* Dialog */
.c-dialog {
  border-radius: 10px;
}

.c-dialog__header {
  /* justify-content: center; */
}

.c-dialog__title {
  color: inherit;
  font-family: var(--font-body);
  font-size: var(--g-font-size-x-large);
}

.c-dialog__content {
  color: #707070;
}

.c-dialog__buttons {
  flex-direction: column-reverse;
}
