.c-header-menu__list {
  align-items: center;
  display: flex;
}

.c-header-menu__item {
  margin: 0 var(--g-spacing-x-large) 0 0;
  position: relative;
}

.c-header-menu__item:last-of-type {
  margin-right: 0;
}

.c-header-menu__link {
  align-items: center;
  display: flex;
  color: var(--g-color-white);
  opacity: 0.6;
  padding: 0 var(--g-spacing-x-small);
  position: relative;
  transition: 200ms ease-in-out;
}

.c-header-menu__link:hover,
.c-header-menu__link--active {
  opacity: 1;
}

.c-header-menu__icon {
  width: var(--g-font-size-large);
}

.c-header-menu__label {
  margin: 0 0 0 var(--g-spacing-x-small);
}

.c-header-menu__notification {
  align-items: center;
  background-color: var(--color-accent);
  border-radius: 50%;
  display: flex;
  height: var(--g-font-size);
  font-size: 0.5rem;
  font-weight: 600;
  justify-content: center;
  position: absolute;
  right: 0;
  transform: translate(100%, -50%);
  top: 50%;
  width: var(--g-font-size);
}
