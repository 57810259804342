.c-user-connections {
  margin-bottom: var(--g-spacing);
}

.c-user-connections__count {
  font-size: var(--g-font-size-small);
  font-weight: 600;
}

.c-user-connections__count:not(:first-of-type) {
  margin-left: var(--g-spacing-x-large);
}
