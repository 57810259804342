.c-notifications-menu {
  margin-left: auto;
  margin-right: var(--g-spacing-x-large);
}

.c-notifications-menu__message {
  font-size: var(--g-font-size-x-small);
  margin: 0;
}

.c-notifications-menu ul {
  margin: 0;
}

.c-notifications-menu li {
  font-size: var(--g-font-size-x-small);
  margin: 0;
}

.c-notifications-menu li img {
  margin-right: 0.35rem;
  max-width: 0.85rem;
}

.c-notifications-menu a {
  align-items: center;
  color: Var(--g-color-black);
  display: inline-flex;
  font-size: var(--g-font-size-x-small);
  padding: var(--g-spacing-x-small) var(--g-spacing-small);
  width: 100%;
}

.c-notifications-menu a:hover {
  background-color: #f1f4f5;
}

.c-notifications-menu li:last-of-type a {
  background-color: #f1f4f5;
  justify-content: center;
}

.c-notifications-menu .c-dropdown-menu__dropdown {
  width: 15rem;
}

.c-notifications-menu__status {
  background-color: var(--color-primary);
  border-radius: 50%;
  height: var(--g-spacing-x-small);
  margin-right: var(--g-spacing-x-small);
  min-width: var(--g-spacing-x-small);
  width: var(--g-spacing-x-small);
}
