/* Attention cssStyle */
.ek-attention {
  align-items: flex-start;
  background-color: var(--blue-700);
  border-radius: 15px;
  color: var(--white);
  display: flex;
  padding: 0.85rem;
  position: relative;
}

.ek-attention p:last-of-type {
  margin-bottom: 0;
}

.ek-attention .c-snippet__icon {
  display: flex;
  margin-right: 0.85rem;
  max-width: 2rem;
  width: 100%;
}

/* Journal table */

.c-table-journal {
  margin-bottom: 1.5rem;
}

.c-table-journal .netfront-ui-external-antd {
  background-color: transparent;
}

.c-table-journal .ant-picker {
  width: 100%;
}

.c-table-journal .c-table__tbody-td {
  padding-bottom: 0.85rem;
  padding-top: 0;
  vertical-align: top;
}

.c-table-journal .c-table__tbody-tr:hover {
  background-color: initial;
}

.c-table-journal .c-textarea--label {
  display: none;
}

.c-table-journal tr:last-of-type {
  margin-top: 2rem;
}

/* Range slider text lables: /dashboard/module-1/developing-goals-and-actions */
.c-snippet .qa-content-snippet-id-23973 .c-dompurify-sanitized-html-content {
  display: flex;
  justify-content: space-between;
}

/* Remove activity section background color: https://d3iwyth2b48oc5.cloudfront.net/dashboard/module-2/attention-training */
.ek-section-11338 {
  background-color: transparent;
}

/* True false */

.ek-true-false-section .c-question-snippet {
  margin-bottom: 0;
}

.ek-true-false-section .c-question-snippet__question {
  font-weight: 300;
}

.ek-true-false-section .c-radio-button__container {
  margin-right: 0.5rem;
}

.ek-true-false-section img {
  border-radius: 15px;
}

.ek-button-radio fieldset {
  align-items: baseline;
  display: flex;
  margin: 0 -0.2rem;
}

.ek-button-radio .c-radio-button .c-label {
  border: 2px solid var(--blue-700);
  border-radius: 15px;
  color: var(--blue-700);
  height: var(--spacing-16);
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  justify-content: center;
  min-width: 7rem;
  padding-left: var(--spacing-6);
  padding-right: var(--spacing-6);
}

.ek-button-radio .c-radio-button + .c-radio-button {
  margin-left: 0.5rem;
}

.ek-button-radio .c-radio-button__item:checked ~ label {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
  color: #fff;
}

.ek-button-radio .c-radio-button__item ~ label:before {
  display: none;
}

.ek-button-radio .c-radio-button .c-label__text {
  display: inline-block;
  line-height: 1.4;
  margin: 0;
  text-align: center;
}

.ek-correct,
.ek-incorrect {
  padding: 0 !important;
}

.ek-correct h5,
.ek-incorrect h5 {
  border-radius: 15px 15px 0 0;
  color: var(--white);
  padding: 1rem;
}

.ek-correct p,
.ek-incorrect p {
  padding: 1rem;
}

.ek-correct h5 {
  background-color: var(--color-primary);
}

.ek-incorrect h5 {
  background-color: var(--blue-700);
}

/* Tookbox side widget */
.c-toolkit {
  align-items: flex-start !important;
  background-color: transparent !important;
  justify-content: flex-start !important;
  position: relative;
  top: 0 !important;
  left: 0;
  transform: initial !important;
  z-index: 0;
}

.c-toolkit button {
  border-radius: 50% !important;
}

.qa-content-snippet-id-24103 .l-layout__item {
  margin-bottom: 0 !important;
}

/* INline radios: questionnaire/baseline/section-3 */
.ek-container-14230 fieldset {
  display: flex;
  justify-content: space-between;
}

.ek-container-14230 .c-radio-button .c-label {
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}

.ek-container-14230 .c-radio-button + .c-radio-button {
  margin-top: 0;
}

/* Css style classes */

.ek-question-feedback,
.ek-instructions,
.ek-activity,
.ek-important {
  align-items: center;
  background-color: var(--white);
  border: 2px solid;
  border-radius: 0 10px 10px 10px !important;
  display: flex;
  margin: var(--spacing-12) 0 0 0;
  padding: var(--spacing-4) !important;
  position: relative;
}

.ek-question-feedback .c-snippet__icon,
.ek-instructions .c-snippet__icon,
.ek-activity .c-snippet__icon,
.ek-important .c-snippet__icon {
  align-items: center;
  border: 2px solid;
  border-radius: 10px 10px 0 0;
  display: flex;
  height: var(--spacing-12);
  justify-content: center;
  left: -1px;
  padding: var(--spacing-2);
  position: absolute;
  top: -2px;
  transform: translate(0, -100%);
  width: var(--spacing-12);
}

.ek-question-feedback {
  background-color: var(--white);
  border-color: var(--color-primary);
}

.ek-instructions,
.ek-important {
  background-color: var(--white);
  border-color: var(--pink-400);
}

.ek-instructions .c-snippet__icon,
.ek-important .c-snippet__icon {
  background-color: var(--pink-400);
  border-color: var(--pink-400);
}

.ek-question-feedback .c-snippet__icon {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}

.ek-activity {
  background-color: var(--white);
  border-color: var(--blue-700);
}

.ek-activity .c-snippet__icon {
  background-color: var(--blue-700);
  border-color: var(--blue-700);
}

@media (min-width: 64em) {
  .ek-question-feedback,
  .ek-instructions,
  .ek-activity,
  .ek-important {
    margin: 0 0 0 var(--spacing-12);
  }

  .ek-question-feedback .c-snippet__icon,
  .ek-instructions .c-snippet__icon,
  .ek-activity .c-snippet__icon,
  .ek-important .c-snippet__icon {
    border-radius: 10px 0 0 10px;
    padding: var(--spacing-2);
    transform: translate(-100%, 0);
  }
}

.ek-feedback {
  align-items: center;
  background-color: var(--white);
  border: 3px solid;
  border-color: var(--blue-700);
  border-radius: 0 10px 10px 10px;
  display: flex;
  margin: var(--spacing-12) 0 0 0;
  padding: var(--spacing-6);
  position: relative;
}

.ek-feedback p {
  margin: 0;
}

.ek-feedback .c-snippet__icon {
  align-items: center;
  background-color: var(--blue-700);
  border: 3px solid;
  border-color: var(--blue-700);
  border-radius: 10px 10px 0 0;
  display: flex;
  height: var(--spacing-12);
  justify-content: center;
  left: -3px;
  padding: var(--spacing-2);
  position: absolute;
  top: -3px;
  transform: translate(0, -100%);
  width: var(--spacing-12);
}

@media (min-width: 64em) {
  .ek-feedback {
    margin: 0 0 0 var(--spacing-12);
  }

  .ek-feedback .c-snippet__icon {
    border-radius: 10px 0 0 10px;
    padding: var(--spacing-2);
    transform: translate(-100%, 0);
  }
}

.ek-align-bottom .l-layout__item {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.ek-align-spread .ek-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
}

/*  iconquer */
.ek-section-11662 {
  padding: 0 !important;
}

.ek-section-11800 {
  margin-bottom: 0 !important;
  padding: 0 !important;
}

.ek-section-11800 .l-layout {
  margin: 0;
}

.ek-section-11800 .l-layout__item {
  margin-bottom: 0 !important;
  padding: 0 !important;
}

.ek-section-11800 .c-question-snippet {
  margin-bottom: 0 !important;
}

.ek-section-11800 .c-question-snippet__question {
  font-weight: initial;
}

/* Match cards */
.c-question-answer-match__card {
  align-items: center;
  background-color: var(--white);
  box-shadow: 20px 23px 46px #00a4b833;
  border: 1px solid #b7d7db;
  border-radius: 15px;
  display: flex;
  flex-direction: column;
  max-width: 19rem;
  margin: 0 auto 3rem auto;
  padding: 1.5rem;
  position: relative;
  width: 85%;
}

.c-question-answer-match__card::after {
  background-color: #fff;
  border: 1px solid #b7d7db;
  border-radius: 15px;
  box-shadow: 20px 23px 46px #00a4b833;
  content: '';
  height: 1.2rem;
  left: 50%;
  position: absolute;
  top: 0;
  transform: translate(-50%, -50%);
  width: 80%;
  z-index: -1;
}

.c-question-answer-match__card-counter {
  color: var(--color-primary);
  font-weight: 600;
  font-size: 0.85rem;
}

.c-question-answer-match__card-label {
  margin-bottom: 1rem;
}

.c-question-answer-match__accordion .c-accordion {
  border: none !important;
}

.c-question-answer-match__accordion .c-accordion__item-parent-container {
  background-color: var(--color-primary);
  border-radius: 15px;
  color: var(--white);
  margin-bottom: 1rem;
}

.c-question-answer-match__accordion .c-accordion__item-parent-container:first-of-type {
  background-color: var(--blue-700);
}

.c-question-answer-match__accordion .c-accordion__item-parent-container:last-of-type {
  background-color: #00c6de;
}

.c-question-answer-match__accordion .c-accordion__item-container {
  border: none;
  padding: 0.75rem;
}

.c-question-answer-match__info-component span {
  margin-bottom: 0;
}

.c-question-answer-match__accordion .c-accordion__item-contents-container {
  font-weight: 600;
}

.c-question-answer-match__accordion .c-accordion__item-dropdown-icon-container {
  margin-left: 0.5rem;
}

.c-accordion__item-child-container {
  margin-top: -1rem;
}

.c-question-answer-match__accordion .c-select-list__label {
  display: none;
}

.c-question-answer-match__accordion .c-select-list__input {
  width: calc(100% + 1rem);
}

.c-question-answer-match__accordion .c-select-list__icon {
  right: 0;
}

.c-question-answer-match__accordion .c-button-icon-only {
  background-color: transparent;
}

.c-question-answer-match__accordion .c-button-icon-only svg {
  color: #fff;
  stroke: #fff;
}

/* Range slider labels */
.qa-content-snippet-id-24937 .c-dompurify-sanitized-html-content,
.qa-content-snippet-id-24935 .c-dompurify-sanitized-html-content,
.qa-content-snippet-id-24936 .c-dompurify-sanitized-html-content,
.qa-content-snippet-id-25035 .c-dompurify-sanitized-html-content {
  display: flex;
  justify-content: space-between;
  margin-top: -1rem;
  width: 100%;
}

/* Summary feedback ranking */
.c-question-response-draggable-item__delete-button,
.c-question-response-draggable-item__decrement-rank,
.c-question-response-draggable-item__increment-rank {
  margin: 0 0 0 1rem;
}

.c-question-response-draggable-item__decrement-rank .c-button-icon-only--with-border,
.c-question-response-draggable-item__increment-rank .c-button-icon-only--with-border,
.c-question-response-draggable-item__collapse-expand .c-button-icon-only--with-border {
  border: 2px solid #00a4b8;
  height: 1.85rem;
  padding: 0.5rem;
  width: 1.85rem;
}

.c-question-response-draggable-item__rank-container {
  margin-right: 1rem;
}

.c-question-response-draggable-item__rank-button {
  border: 2px solid #00a4b8;
  cursor: pointer !important;
  font-weight: 600;
  transition: 100ms ease-in-out;
}

.c-question-response-draggable-item__rank-button:hover {
  background-color: #00a4b8;
}

.c-question-response-draggable-item__rank-button:disabled {
  background-color: #00a4b8;
  color: #fff;
}

/* Hide summary feedback on load */
.ek-section-11889 {
  display: none;
}

.ek-section-11428 .chartjs-render-monitor {
  background: linear-gradient(to top, #fef1ff 40%, #e1f2f3 55%);
}

.c-spotlight-link {
  padding: 0;
  margin-right: var(--spacing-4);
}

.c-spotlight-link a {
  background-color: #00a4b8;
  color: #fff;
  font-weight: 600;
  padding: var(--spacing-4) var(--spacing-8);
  border: 1px solid #00a4b8;
  border-radius: 50px;
  text-decoration: none;
}

.c-spotlight-link a:focus {
  background-color: #fff;
  color: #00a4b8;
}

@media screen and (min-width: 64em) {
  .c-spotlight-link a:hover {
    background-color: #fff;
    color: #00a4b8;
  }
}

.c-flip-card {
  height: 13rem;
}

/* Hide delete buttons: dashboard/module-1/developing-goals-and-actions */
.ek-container-15150 .c-question-response-draggable-item__delete-button,
.ek-container-15161 .c-question-response-draggable-item__delete-button {
  display: none;
}
