.ant-picker input:read-only {
  background-color: var(--white) !important;
}

.ant-picker-suffix,
.anticon.anticon-calendar {
  margin-bottom: 0;
}

.ant-picker-input input:hover {
  border: none !important;
}

.ant-picker-focused,
.ant-picker:hover {
  border: 3px solid var(--color-primary) !important;
}
