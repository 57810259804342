.c-poll {
  padding: 0 var(--g-spacing);
}

.c-poll-option__container {
  align-items: center;
  display: flex;
}

.c-poll--voted .c-poll-option,
.c-poll--disabled .c-poll-option {
  background-color: #f1f4f5;
  border-color: #f1f4f5;
  box-shadow: inset 0 0 0 1px #f1f4f5;
}

.c-poll__legend {
  padding-bottom: var(--g-spacing);
}

.c-poll-option {
  box-shadow: inset 0 0 0 1px var(--color-primary);
  border-radius: 10px;
  cursor: pointer;
  margin-bottom: var(--g-spacing-x-small);
  flex: 1;
  overflow: hidden;
  padding: 0.5rem 0.75rem;
  position: relative;
  transition: 0.1s ease-in-out;
}

.c-poll--voted .c-poll-option--voted {
  box-shadow: inset 0 0 0 1px var(--color-primary);
}

.c-poll--voted .c-poll-option__scale {
  background-color: #bed1d8;
  cursor: not-allowed;
}

.c-poll:not(.c-poll--voted):not(.c-poll--disabled) .c-poll-option:hover {
  background-color: var(--color-primary);
}

.c-poll-option__input {
  cursor: pointer;
  height: 100%;
  opacity: 0;
  left: 0;
  margin: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.c-poll-option__label {
  cursor: pointer;
  font-weight: 300;
  position: relative;
}

.c-poll-option__scale {
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  transition: 1s width cubic-bezier(0.25, 0.1, 0.25, 1);
  width: 0;
}

.c-poll-option__input:checked ~ .c-poll-option__scale {
  background-color: var(--color-primary);
  width: 30%;
}

.c-poll-option__percentage {
  margin-left: var(--g-spacing-small);
  text-align: right;
  width: var(--g-spacing-3x-large);
}

.c-poll-option__percentage--voted {
  color: var(--color-primary);
}

.c-poll__votes {
  display: block;
  font-size: var(--g-font-size-small);
  padding: var(--g-spacing-2x-small) 0 var(--g-spacing-small) 0;
}

.c-poll__end-date {
  color: #000;
  font-size: var(--g-font-size-x-small);
}

.c-poll-option__scale--selected {
  background-color: var(--color-primary) !important;
  cursor: not-allowed;
}
