.c-dropdown-menu {
  color: var(--g-color-black);
  margin-left: var(--g-spacing-x-large);
  position: relative;
}

.c-dropdown-menu__dropdown {
  background-color: var(--g-color-white);
  border-radius: 5px;
  box-shadow: 0px 0px 20px #84848454;
  color: var(--g-color-black);
  margin: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  transform: translateY(10px);
  top: 100%;
  width: 12rem;
  z-index: 2;
}

.c-dropdown-menu__dropdown::before {
  background-color: var(--g-color-white);
  box-shadow: 0px 0px 20px #84848454;

  content: '';
  height: var(--g-spacing-x-small);
  position: absolute;
  width: var(--g-spacing-x-small);
  transform: translate(-50%, -50%) rotate(45deg);
  right: 5%;
  z-index: -1;
}
