.c-embed-snippet {
  border-radius: 5px;
}

.c-embed-snippet__title {
  display: none;
}

.c-code-snippet p {
  margin-bottom: 0;
}

/* Link buttons */

.c-link-button {
  background-color: var(--color-primary);
  border: 2px solid;
  border-color: var(--color-primary);
  border-radius: 10px;
  padding: var(--spacing-3) var(--spacing-4);
  transition: 100ms ease-in-out;
}

.c-link-button .c-link-button__text {
  color: var(--white) !important;
  font-family: var(--font-header);
}

.c-link-button:hover {
  background-color: var(--color-primary);
}

.c-link-button:hover .c-link-button__text {
  color: var(--white) !important;
}

/* Textareas */
.c-question-snippet {
  margin-bottom: 1.5rem;
}

.c-question-snippet .c-header__container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin: 0 0.5rem 1rem 0.5rem;
}

.c-question-snippet .c-header__container .netfront-ui-external-antd {
  height: auto;
}

.c-question-snippet .c-header__container .netfront-ui-external-antd .ant-picker {
  width: 100%;
}

.c-question-snippet .h-flex.h-vertical-flex-direction .h-flex {
  flex-direction: column;
  margin: 0 -0.5rem;
}

.c-add-new-response-button__container .h-flex {
  margin: 0 !important;
}

.multi-response-text-how-many-more-responses-can-be-added {
  display: none;
}

@media (min-width: 72em) {
  .c-question-snippet .h-flex.h-vertical-flex-direction .h-flex {
    flex-direction: row;
  }
}

.c-question-snippet__question {
  align-items: center;
  display: flex;
  font-weight: 600;
}

.c-question-snippet__major {
  align-items: center;
  background-color: var(--blue-800);
  border-radius: 50%;
  color: var(--white) !important;
  display: flex;
  height: var(--spacing-8);
  justify-content: center;
  margin-right: 1rem;
  min-width: var(--spacing-8);
  padding-top: var(--spacing-1);
  width: var(--spacing-8);
}

.c-input-field__input {
  background-color: var(--white);
  border: 3px solid transparent;
  border-radius: 8px;
  font-family: var(--font-body);
  font-size: 1rem;
  outline: none;
  padding: 0.85rem;
  transition: 250ms ease-in-out;
}

.c-input-field__input--textarea {
  min-height: 6rem !important;
}

.c-input-field__input:focus {
  border-color: var(--color-accent);
}

/* Carousel + quote snippet */

.flicking-pagination-bullet-active {
  background-color: var(--white);
}

.flicking-pagination-bullets {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flicking-pagination-bullet {
  background-color: var(--white);
  border: 2px solid var(--color-accent);
  height: 1rem;
  width: 1rem;
}

.flicking-arrow-next:after,
.flicking-arrow-next:before,
.flicking-arrow-prev:after,
.flicking-arrow-prev:before {
  background-color: var(--color-accent) !important;
}

/* .flicking-arrow-next,
.flicking-arrow-prev {
  display: none;
} */

.c-slider-snippet__container {
  background-color: var(--white);
  border-radius: 10px;
  padding: var(--spacing-6);
}

.c-slider-snippet__container h2 {
  margin-bottom: 1rem;
}

.c-slider-snippet__container .flicking-pagination-bullets .flicking-pagination-bullet {
  padding: 0;
}

.c-slider-snippet__container .flicking-pagination-bullets {
  margin-top: var(--spacing-4);
}

.c-slider-snippet__container .flicking-pagination-bullets span {
  padding: 0 var(--spacing-2);
}

.c-slider-snippet__container .flicking-pagination-bullets .flicking-pagination-bullet::before {
  background-color: var(--spacing-4);
  border: 2px solid #ade2e0;
  height: var(--spacing-4);
  width: var(--spacing-4);
}

.c-slider-snippet__container .flicking-pagination-bullets .flicking-pagination-bullet-active {
  border: 2px solid var(--color-accent);
  border-radius: 50px;
  height: 1.25rem;
  padding: 0.13rem;
  position: relative;
  width: 2.25rem;
}

.c-slider-snippet__container .flicking-pagination-bullets .flicking-pagination-bullet-active::before {
  background-color: var(--color-accent);
  border: none;
  border-radius: 50px;
  content: '';
  display: block;
  height: 100%;
  padding: 0;
  width: 100%;
}

.c-quote {
  text-align: center;
}

.c-quote__content {
  font-style: normal;
}

.c-quote__speech-marks {
  display: none !important;
}

.c-quote__speech-marks--open {
  background-color: var(--color-accent);
  color: var(--white);
  display: inline-flex;
  padding: 1rem;
  border-radius: 10px;
  margin-bottom: var(--spacing-6);
}

.c-quote__speech-marks--close {
  display: none;
}

.c-quote__author {
  font-weight: 500;
  font-style: initial;
  font-size: 1rem;
  margin-bottom: var(--spacing-4);
  margin-top: var(--spacing-8);
  text-align: center;
}

.netfront-ui-external-brainhubeu .c-event-trigger__icon {
  display: none;
}

/* Brief COPE Quiz results */

/* Vertical radio list */
.ek-vertical-radio-list .c-question-snippet fieldset {
  flex-direction: column;
}

.ek-vertical-radio-list .c-question-snippet fieldset legend {
  display: none;
}

.ek-vertical-radio-list .c-question-snippet__question {
  margin-bottom: var(--spacing-2);
}

.ek-vertical-radio-list .c-radio-button .c-label {
  cursor: pointer;
}

/* Vertical checkbox list (h-hide-visually causes issues when more than one form is on the page) */

.ek-vertical-checkbox-list .c-checkbox {
  display: flex;
  margin: 0 0 var(--spacing-6) 0;
  position: relative;
}

.c-summary-snippet * {
  cursor: default !important;
}

.c-summary-snippet .c-user-generated-response__container,
.c-summary-snippet .checkbox-item-delete-button-container {
  display: none;
}

.ek-question p:not(:last-of-type),
.ek-hint p:not(:last-of-type),
.ek-info-box p:not(:last-of-type),
.ek-warning p:not(:last-of-type),
.ek-puzzle p:not(:last-of-type) {
  margin-bottom: 1rem;
}

/* Range slider label theme */

.ek-range-slide .ek-text-snippet {
  display: flex;
  justify-content: space-between;
  margin-top: var(--spacing-8);
}

/* Multi response */
.multi-response-text-add-new-response-button {
  background-color: var(--color-primary);
  border-radius: 50px;
  color: var(--white);
  cursor: pointer;
  display: block;
  font-weight: 700;
  margin: 0 auto;
  min-height: 3.5rem;
  padding: 0 2rem;
  width: auto;
}

.multi-response-text-add-new-response-button:disabled {
  background-color: var(--grey-200);
}

/* Table animations */
.animate__fadeIn {
  opacity: 0;
  animation: fadeIn 500ms cubic-bezier(0.23, 1, 0.32, 1) forwards;
}

.animate__delay-2s {
  animation-delay: 2s;
}

.animate__delay-3s {
  animation-delay: 3s;
}

.animate__delay-4s {
  animation-delay: 4s;
}

.animate__delay-5s {
  animation-delay: 5s;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.multi-response-text-minimum-responses-required-help-text {
  display: none;
}
