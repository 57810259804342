.c-post-message-editor {
  background-color: var(--g-color-white);
  border-radius: 10px;
  padding: var(--g-spacing);
  margin-bottom: var(--g-spacing);
  position: relative;
}

.c-post-message-editor__textarea {
  border-color: transparent;
  width: 100%;
}

.c-post-message-editor__textarea:hover {
  border-color: transparent !important;
}

.c-post-message-editor__textarea:focus {
  border-color: var(--color-primary) !important;
}

.c-post-message-editor__editor {
  /* background-color: #fff; */
  min-height: 7rem;
  padding: 0;
  outline: 0;
}

.c-post-message-editor__editor textarea {
  min-height: auto;
  padding: 0 !important;
}

.c-post-message-editor__editor textarea,
.c-post-message-editor__editor textarea:hover,
.c-post-message-editor__editor textarea:focus {
  border-color: transparent !important;
}

.c-post-message-editor__editor__suggestions {
  box-shadow: 0px 0px 20px #84848454;
  border-radius: 8px;
  position: absolute;
  background-color: #fff;
  min-width: 11rem !important;
  overflow: hidden;
}

.c-post-message-editor__editor__suggestions__item {
  align-items: center;
  display: flex;
  margin: 0;
  padding: 0.5rem;
}

.c-post-message-editor__editor__suggestions__item:hover {
  background-color: #f2f5f6;
}
