.c-post-button {
  align-items: center;
  cursor: pointer;
  display: flex;
}

.c-post-button__icon {
  margin-bottom: var(--g-spacing-2x-small);
  margin-right: var(--g-spacing-2x-small);
  height: var(--g-spacing-x-large);
  width: var(--g-spacing-x-large);
}

.c-post-button__count {
  font-weight: 500;
  margin-bottom: 0;
}

.c-post-button:disabled .c-post-button__count {
  color: #bed1d8;
}

.c-post-button .c-post-button__icon .c-icon__outline {
  stroke: #48756f;
}

.c-post-button:disabled .c-post-button__icon .c-icon__outline {
  stroke: #bed1d8;
}

.c-post-button .c-post-button__icon--active .c-icon__outline {
  stroke: var(--color-primary);
}

.c-post-button:hover:not(:disabled) .c-post-button__icon .c-icon__outline {
  stroke: var(--color-primary);
  transition: 150ms ease-in-out;
}
