.c-post-share-dialog {
  padding: var(--g-spacing-2x-large);
}

.c-post-share__user {
  align-items: center;
  display: flex;
}

.c-post-share-dialog .c-dialog__header {
  background-color: transparent;
}

.c-post-share-dialog .c-dialog__content {
  text-align: left;
}

.c-post-share-dialog__textarea {
  margin: var(--g-spacing) 0;
  width: 100%;
}
