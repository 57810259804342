.c-logo-link {
  display: inline-block;
}

.c-logo-link--md {
  max-width: 12rem;
}

.c-logo-link--lg {
  max-width: 14rem;
}

.c-logo-link--xl {
  max-width: 16rem;
}

.c-logo-link--2xl {
  max-width: 20rem;
}
