.c-help-button {
  align-items: center;
  background-color: var(--color-accent);
  border-radius: 50%;
  cursor: pointer;
  display: inline-flex;
  height: var(--spacing-12);
  justify-content: center;
  padding: var(--spacing-3);
  text-align: center;
  width: var(--spacing-12);
}

.c-help-button__fixed {
  bottom: 2rem;
  position: fixed;
  right: 2rem;
}

.c-help-button__icon {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-right: 0;
}

.c-help-button__text {
  color: var(--white);
  display: none;
  font-weight: 700;
  margin-bottom: 0;
}

@media (min-width: 64em) {
  .c-help-button:not(.c-help-button--compact) {
    border-radius: 50px;
    height: var(--spacing-14);
    padding: 0 var(--spacing-6);
    width: auto;
  }

  .c-help-button:not(.c-help-button--compact) .c-help-button__icon {
    margin-right: 0.5rem;
  }

  .c-help-button:not(.c-help-button--compact) .c-help-button__text {
    display: inline-block;
  }
}
