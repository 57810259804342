.c-user-details-header {
  display: flex;
}

.c-user-details-header__avatar {
  margin-right: var(--g-spacing-x-small);
  position: relative;
}

.c-user-details-header__details {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.c-user-details-header__avatar .c-avatar,
.c-user-details-header__details .c-user-details-header__description {
  margin-bottom: 0;
}

.c-user-details-header__avatar .c-avatar img {
  object-fit: cover;
  width: 100%;
}

.c-user-details-header__display-name {
  color: var(--g-color-black);
  font-size: var(--g-font-size);
  font-weight: 600;
  line-height: 1;
  margin-bottom: 0;
}

.c-user-details-header__description {
  color: #000;
  font-size: var(--g-font-size-x-small);
}
