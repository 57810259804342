.c-post {
  background-color: var(--g-color-white);
  box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
  border-radius: 10px;
  margin-bottom: var(--g-spacing-small);
}

.c-post-user {
  display: flex;
  padding: var(--g-spacing);
}

.c-post-user__avatar {
  margin-right: var(--g-spacing-x-small);
  position: relative;
}

.c-post-user__details {
  display: flex;
  flex-direction: column;
}

.c-post-user__avatar .c-avatar,
.c-post-user__details .c-post-user__date {
  margin-bottom: 0;
}

.c-post-user__date {
  color: #848484;
  font-size: var(--g-font-size-x-small);
}

.c-post-user__display-name {
  color: var(--g-color-black);
  font-size: var(--g-font-size);
  font-weight: 600;
  line-height: 1;
  margin-bottom: 0;
}

.c-post-user__settings {
  align-items: center;
  display: flex;
  margin-left: auto;
}

.c-post-user__settings span {
  margin-bottom: 0;
}

.c-post-user__settings .c-icon {
  height: var(--g-spacing);
  width: var(--g-spacing);
}

.c-post-user__settings .c-dropdown-toggle {
  cursor: pointer;
  width: var(--g-spacing-x-large);
  height: var(--g-spacing-x-large);
}

.c-post-message {
  margin-bottom: var(--g-spacing);
  line-height: 1.5;
  padding: var(--g-spacing);
  white-space: pre-wrap;
}

.c-post-message--highlight,
.c-post-message--highlight a {
  color: #487570;
  font-weight: 400;
}

.c-post-details {
  border: 1px solid #f5f7f9;
  display: flex;
  padding: var(--g-spacing-small) 0;
}

.c-post-details__item {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 33.333%;
}

.c-post-footer {
  padding: var(--g-spacing);
}

.c-post-footer__comment-button {
  color: var(--color-primary);
  cursor: pointer;
  font-size: var(--g-font-size-small);
  margin-left: auto;
}

.c-post-user__avatar--skeletion {
  border-radius: 50%;
  height: var(--g-spacing-2x-large);
  width: var(--g-spacing-2x-large);
}

.c-post-user__display-name--skeleton {
  height: var(--g-spacing-small);
  width: var(--g-spacing-5x-large);
}

.c-post-user__date--skeleton {
  height: var(--g-spacing-x-small);
  width: var(--g-spacing-5x-large);
}

.c-post-message--skeleton {
  border-radius: 40px;
  height: var(--g-spacing-small);
}

.c-post-message span:last-of-type .c-post-message--skeleton {
  width: 30%;
}

.c-post-reply {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  padding: var(--g-spacing) 0;
  width: 100%;
}

.c-post-reply .c-textarea__container {
  width: 100%;
}

.c-post-reply textarea {
  margin-bottom: var(--g-spacing-small);
  min-height: 6rem;
}

.c-post__pin {
  width: var(--g-spacing);
}

.c-post-options ul,
.c-post-options li {
  margin: 0;
}

.c-post-options li button {
  cursor: pointer;
  font-size: var(--g-font-size-small);
  padding: var(--g-spacing-2x-small) var(--g-spacing-small);
  text-align: left;
  width: 100%;
}

.c-post-options li button:hover {
  background-color: #f2f5f6;
}

.c-post-relates {
  align-items: center;
  cursor: pointer;
  display: flex;
}

.c-post-relates span {
  color: #487570;
  font-size: var(--g-font-size-small);
  margin: 0 0 0 var(--g-spacing-x-small);
  font-weight: 500;
  line-height: 1.5;
}

.c-post-relates__icon {
  align-items: center;
  display: flex;
  margin-right: var(--g-spacing);
  position: relative;
}

.c-post-relates__icon svg {
  position: relative;
}

.c-post-relates__icon::before {
  background-color: #48756f;
  border-radius: 50%;
  content: '';
  height: 18px;
  left: 6px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 18px;
  z-index: 0;
}

.c-post-image {
  border-radius: 25px;
  display: block;
  margin: 0 auto var(--g-spacing) auto;
  max-height: 26rem;
  padding: var(--g-spacing);
}

.c-post .c-post-feeling {
  padding: 0 var(--g-spacing);
}

.c-post__mention {
  background: #f1f4f5;
  border: 1px solid #bed1d8;
  border-radius: 40px;
  color: #000;
  font-size: var(--g-font-size-small);
  padding: 3px 6px;
}

.c-post__community {
  align-items: flex-end;
  display: inline-flex;
  margin-left: var(--g-spacing-x-small);
}

.c-post__community * {
  color: #487570;
  font-size: var(--g-font-size-x-small);
  margin-bottom: 0;
}


.c-post__badge {
  border-radius: 40px;
  background-color: #487570;
  color: #fff;
  margin-left: .5rem;
  padding: .125rem .5rem 0 .5rem;
}