.c-communities-page__container {
  max-width: var(--container-width-sm);
  margin: 0 auto;
  padding: var(--g-spacing-2x-large) 0 0 0;
  width: 90%;
}

.c-communities-page__search {
  margin-bottom: var(--g-spacing-x-large);
}

.c-communities-page__container h1,
.c-communities-page__container h2 {
  font-family: var(--font-body);
}

.c-communities-page__suggestions {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: var(--g-spacing-2x-large) 0 var(--g-spacing);
}

.c-communities-page__suggestions h2 {
  margin-bottom: 0;
}

.c-communities-page__suggestions a {
  color: var(--color-primary);
  font-size: var(--g-font-size-small);
  font-weight: 600;
}
