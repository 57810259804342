.c-conversation-message {
  display: flex;
}

.c-conversation-message:not(:last-of-type),
.c-conversation-message:only-of-type {
  margin-bottom: var(--g-spacing-small);
}

.c-conversation-message .c-avatar {
  flex-shrink: 0;
  margin-bottom: 0;
  margin-top: var(--g-spacing-2x-large);
}

.c-conversation-message:not(.c-conversation-message--from-user) .c-avatar {
  margin-right: var(--g-spacing-small);
}

.c-conversation-message .c-avatar img {
  height: 100%;
}

.c-conversation-message--from-user {
  flex-direction: row-reverse;
}

.c-conversation-message--from-user .c-avatar {
  margin-left: var(--g-spacing-small);
}

.c-conversation-message__body {
  display: flex;
  flex-direction: column;
}

.c-conversation-message--from-user .c-conversation-message__body {
  align-items: flex-end;
}

.c-conversation-message--from-user .c-conversation-message__content {
  align-items: flex-end;
}

.c-conversation-message__message {
  background-color: var(--g-color-white);
  border-radius: 10px;
  line-height: 1.3;
  padding: var(--g-spacing-small) var(--g-spacing);
}

.c-conversation-message--from-user .c-conversation-message__message {
  background-color: #48756f;
  color: var(--g-color-white);
}

.c-conversation-message .c-avatar--none {
  margin-top: 0;
  opacity: 0;
}

.c-conversation-message__display-name {
  color: #707070;
  display: block;
  font-size: var(--g-font-size-x-small);
  margin-bottom: var(--g-spacing-x-small);
}

.c-conversation-message--from-user .c-conversation-message__display-name {
  text-align: right;
}

.c-conversation-message__read-status {
  color: #707070;
  display: block;
  font-size: var(--g-font-size-x-small);
  margin-bottom: 0;
  text-align: right;
}

.c-conversation-message--skeleton {
  background-color: var(--g-color-white);
  border-radius: 10px;
  padding: var(--g-spacing);
}

.c-conversation-message__avatar--skeleton {
  border-radius: 50%;
  height: var(--g-spacing-2x-large);
  margin-right: var(--g-spacing);
  width: var(--g-spacing-2x-large);
}

.c-conversation-message__body--skeleton {
  width: 100%;
}

.c-conversation-message--from-user .c-conversation-message__body--skeleton {
  text-align: right;
}

.c-conversation-message--from-user .c-conversation-message__avatar--skeleton {
  margin-left: var(--g-spacing-small);
  margin-right: 0;
}
