/* Reveal accordions (with icons) */
.c-event-trigger {
  background-color: var(--color-primary);
  cursor: pointer;
}

.c-event-trigger--text-and-icon {
  background-color: var(--blue-700);
  border-radius: 8px;
  cursor: pointer;
  color: var(--white) !important;
  font-family: var(--font-header);
  margin-top: var(--spacing-4);
  padding: var(--spacing-2) var(--spacing-4);
  text-align: left;
  width: 100%;
}

.c-event-trigger--text-and-icon[aria-expanded='true'] {
  background-color: var(--green-400);
}

.c-event-trigger--text-and-icon .c-event-trigger__title {
  color: var(--white) !important;
  font-family: var(--font-header);
  font-weight: 700;
  margin: 0;
}

.c-accordion__details {
  background-color: var(--white);
  border-radius: 8px;
  padding: var(--spacing-4);
  opacity: 0;
  animation: fadeUp 500ms cubic-bezier(0.23, 1, 0.32, 1) forwards;
}

.c-event-trigger__icon-container {
  margin: 0;
}

.c-event-trigger__icon {
  background-color: var(--white);
  border-radius: 50%;
  color: var(--color-accent);
}

/* Popup text only */
.c-event-trigger--text-only {
  border-radius: 50px;
  display: block;
  font-weight: 700;
  margin: 0 auto;
  min-height: 3.5rem;
  padding: 0 2rem;
  width: auto;
}
