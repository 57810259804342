/* Radio buttons */

.c-radio-button {
  position: relative;
}

.c-radio-button .c-label {
  align-items: flex-start;
  cursor: pointer;
}

.c-radio-button__input {
  margin: 0;
  opacity: 0;
}

.c-radio-button__icon {
  border: 3px solid var(--green-300);
  border-radius: 5px;
  margin-top: 3px;
}

.c-radio-button .c-radio-button__input:checked + .c-label .c-radio-button__icon {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}

.c-radio-button__icon::after {
  background-color: var(--color-primary);
}

.c-radio-button .c-label__text {
  display: flex;
  margin: 0 1.5rem 0 0.5rem;
}

.c-radio-button__item {
  height: 1.5rem;
  width: 1.5rem;
  margin: 0;
}

.c-radio-button__item ~ label::before {
  height: 1rem;
  width: 1rem;
}

.c-radio-button__item ~ label::before {
  border: 3px solid #b7d7db;
  border-radius: 50%;
  margin-top: 4px;
}

.c-table-form .c-radio-button__item ~ label::before {
  margin-top: 0;
}

.c-radio-button__item:checked ~ label::before,
.c-radio-button__item:focus ~ label::before {
  background-color: var(--color-primary);
  border: 3px solid var(--color-primary);
}

.c-table-form legend,
.c-table-form .c-label__text {
  display: none !important;
}

.c-table-form .c-table__thead-th-contents-text {
  text-align: left;
}
