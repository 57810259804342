.c-search-input,
.c-search-input__input {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

/* .c-search-input.c-search-input--sm input {
  padding: var(--g-spacing-x-small) var(--g-spacing-small);
} */

.c-search-input__input,
.c-input__container {
  flex: 1;
}

.c-search-input .c-input-field__inner {
  border-color: #fff;
}

.c-search-input__input .c-input-field {
  margin-bottom: 0;
  width: 100%;
}

.c-search-input {
  flex: 1;
}

.c-search-input__icon {
  height: var(--g-spacing);
  left: var(--g-spacing-small);
  position: absolute;
  width: var(--g-spacing);
}

.c-search-input input {
  border-color: transparent;
  min-width: 15rem;
  padding: var(--g-spacing);
  padding-left: var(--g-spacing-x-large);
}

.c-search-input input:hover,
.c-search-input input:focus {
  border-color: var(--color-primary) !important;
}

.c-search-input__button,
.c-search-input__button--close {
  border: none;
  position: absolute;
  right: .5rem;
  top: 50%;
  transform: translateY(-50%);
  height: 80%;
  padding: .75rem !important;
}

.c-search-input__button .c-button__icon {
  color: var(--g-color-white);
  height: var(--g-spacing-large);
  width: var(--g-spacing-large);
}

.c-search-input__button--close .c-button__icon {
  height: 1.2rem;
  width: 1.2rem;
}

.c-search-input__clear {
  background-color: transparent;
  border: none;
  cursor: pointer;
  height: var(--g-spacing);
  position: absolute;
  right: var(--g-spacing-small);
  width: var(--g-spacing);
}

.c-search-input__clear svg {
  height: 100%;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
}

.c-search-input .c-input__icon--before {
  color: #cbccce;
}
