.c-comment {
  background-color: var(--white);
  border-radius: 10px;
  margin: var(--g-spacing-x-large) 0;
}

.c-comment:first-of-type {
  margin-top: 0;
}

.c-comment:last-of-type {
  margin-bottom: 0;
}

.c-comment__avatar {
  margin-right: var(--g-spacing-small);
  position: relative;
}

.c-comment__content {
  flex: 1;
}

.c-comment__avatar .c-avatar,
.c-comment__details .c-comment__date {
  margin-bottom: 0;
}

.c-comment__display-name {
  font-size: var(--g-font-size-x-small);
  font-weight: 700;
  line-height: 1;
  margin-bottom: 0;
}

.c-comment__date {
  color: #848484;
  font-size: var(--g-font-size-2x-small);
}

.c-comment__message p {
  line-height: 1.5;
}

.c-comment__footer {
  align-items: center;
  display: flex;
}

.c-comment__button {
  align-items: center;
  color: var(--color-primary);
  cursor: pointer;
  display: inline-flex;
  font-size: var(--g-font-size-x-small);
  font-weight: 600;
  margin-right: var(--g-spacing-2x-small);
  padding: 0;
}

.c-comment__button:first-of-type {
  width: 4.5rem;
}

.c-comment__icon {
  align-items: center;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  margin-right: var(--g-spacing-2x-small);
  transition: 150ms ease-in-out;
}

.c-comment__icon svg {
  height: var(--g-font-size-large);
  width: var(--g-font-size-large);
}

.c-comment__icon--active {
  background-color: var(--color-primary);
  color: var(--g-color-white);
}

.c-comment__icon#icon-relate-inactive .c-icon__path {
  stroke: var(--color-primary);
}

.c-comment__icon#icon-relate-active .c-icon__path {
  stroke: var(--g-color-white);
}

.c-comment__button:hover .c-comment__icon#icon-relate-inactive .c-icon__path {
  fill: var(--color-primary);
}

.c-comment--sub {
  margin-left: var(--g-spacing-3x-large);
}

.c-comment__reply {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  padding: var(--g-spacing-small) 0 0 0;
  width: 100%;
}

.c-comment__reply textarea {
  font-size: var(--g-font-size-small);
  min-height: var(--g-spacing-6x-large);
}

.c-comment__reply .c-textarea__container {
  margin-bottom: var(--g-font-size-x-small);
  width: 100%;
}
