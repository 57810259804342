.c-online-status {
  border: 2px solid var(--g-color-white);
  border-radius: 50%;
  height: var(--g-spacing);
  position: absolute;
  background: var(--color-primary);
  top: 0;
  right: -4px;
  width: var(--g-spacing);
}

.c-online-status--sm {
  height: var(--g-spacing-small);
  width: var(--g-spacing-small);
}

.c-online-status--lg {
  height: var(--g-spacing-large);
  width: var(--g-spacing-large);
}
