.c-page {
  margin-bottom: var(--g-spacing-2x-large);
  margin-top: 73px;
}

.c-page--with-breadcrumbs {
  margin-top: 118px;
}

.c-page h1 {
  font-family: var(--font-body);
  font-size: var(--g-font-size);
  margin-bottom: var(--g-spacing);
}

.c-page h2 {
  font-family: var(--font-body);
  font-size: var(--g-font-size-small);
  margin-bottom: var(--g-spacing-small);
}

@media (min-width: 64em) {
  .c-page--with-breadcrumbs {
    margin-top: 142px;
  }
}
