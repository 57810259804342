.c-post-relate-dialog {
  padding: var(--g-spacing-2x-large) var(--g-spacing-small) var(--g-spacing);
}

.c-post-relate-dialog .c-dialog__close-icon {
  z-index: 3;
}

.c-post-relate-dialog .c-dialog__content {
  margin-bottom: 0;
  text-align: left;
}

.c-post-relate-dialog .c-dialog__header {
  display: none;
}

.c-post-relate-dialog .c-dialog__content {
  margin-top: 0;
}

.c-post-relate-dialog__header {
  display: flex;
  margin-bottom: var(--g-spacing);
  padding: 0 var(--g-spacing-small) var(--g-spacing-small) var(--g-spacing-small);
}

.c-post-relate-dialog__count {
  align-items: center;
  display: flex;
  margin-right: var(--g-spacing-x-large);
}

.c-post-relate-dialog__count img {
  width: var(--g-spacing-large);
}

.c-post-relate-dialog__count span {
  margin: 0 0 0 var(--g-spacing-x-small);
  font-weight: 600;
}
