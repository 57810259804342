.c-user-initials {
  align-items: center;
  background-color: var(--color-primary);
  border-radius: 50%;
  display: flex;
  min-height: var(--spacing-12);
  justify-content: center;
  min-width: var(--spacing-12);
}

.c-user-initials__text {
  align-items: center;
  color: var(--white);
  display: flex;
  font-weight: 700;
  margin-bottom: 0;
}

.c-user-initials--large {
  min-height: var(--spacing-16);
  min-width: var(--spacing-16);
}

.c-user-initials--large .c-user-initials__text {
  font-size: var(--g-font-size-2x-large);
}
