.c-post-share .c-post {
  border: 2px solid #f1f4f5;
  margin: 0 var(--g-spacing);
}

.c-post-share .c-post-user {
  border-bottom: 2px solid #f1f4f5;
}

.c-post-share .c-post-message,
.c-post-share .c-poll {
  font-size: var(--g-font-size-small);
}
