@media print {
  .kanzi-audio-player {
    display: none !important;
  }
}

@media print {
  .c-snippet {
    page-break-inside: avoid !important;
  }
}
