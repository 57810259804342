/* Checkboxes */

.c-checkbox .c-label {
  align-items: flex-start;
  cursor: pointer;
}
.c-checkbox__icon-container {
  border: 3px solid var(--green-300);
  border-radius: 5px;
  cursor: pointer;
  margin-top: 3px;
}

.c-checkbox__input:checked + .c-label .c-checkbox__icon-container {
  background-color: var(--color-primary);
  border-color: var(--color-primary);
}
