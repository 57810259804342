.c-radio-tabs {
  flex-direction: row;
}

.c-radio-tabs .c-radio-button__label {
  background: var(--g-color-white);
  border: 1px solid transparent;
  border-radius: 10px;
  margin: 0 var(--g-spacing-small) 0 0;
  padding: var(--g-spacing-small) var(--g-spacing-large);
}

.c-radio-tabs div {
  display: flex;
}

.c-radio-tabs .c-label__text {
  margin: 0;
  font-family: var(--font-body);
  text-transform: capitalize;
}

.c-radio-tabs .c-radio-button__item:checked ~ .c-radio-button__label {
  background-color: var(--color-primary);
  color: var(--g-color-white);
}

.c-radio-tabs .c-radio-button__item ~ label::before {
  display: none;
}
