.c-load-more {
  align-items: center;
  background-color: var(--g-color-white);
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin: var(--g-spacing-large) 0;
  padding: var(--g-spacing);
  width: 100%;
}

.c-load-more:disabled {
  cursor: not-allowed;
}

.c-load-more__text {
  font-weight: 600;
  font-size: var(--g-font-size-small);
  margin: 0 var(--g-spacing-small) 0 var(--g-spacing-small);
}
