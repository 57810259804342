.c-empty-message {
  background-color: var(--g-color-white);
  border-radius: 10px;
  padding: var(--g-spacing-3x-large) var(--g-spacing-2x-large);
  position: relative;
  text-align: center;
}

.c-empty-message p {
  color: #848484;
  font-size: var(--g-font-size-small);
  margin-bottom: 0;
}

.c-empty-message svg {
  margin-bottom: var(--g-spacing-x-small);
  width: var(--g-spacing-3x-large);
}

.c-empty-message a {
  color: var(--color-primary);
  font-weight: 600;
}
