.c-notifications-page__title {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: var(--g-spacing-3x-large) 0 var(--g-spacing-large) 0;
}

.c-notifications-page__title h1 {
  font-family: var(--font-body);
  font-size: var(--g-font-size-2x-large);
}

.c-notifications-page .c-search-input {
  margin-bottom: var(--g-spacing-2x-large);
}

.c-notifications-page h2 {
  font-size: var(--g-font-size-x-large);
  margin-bottom: var(--g-spacing);
}

.c-notifications-page h3 {
  font-size: var(--g-font-size-large);
  font-family: var(--font-body);
  margin-bottom: var(--g-spacing-x-small);
}

.c-notifications-page h4 {
  color: #707070;
  font-size: var(--g-font-size-x-small);
  font-family: var(--font-body);
  font-weight: 400;
  margin-bottom: var(--g-spacing);
}

.c-notifications-page .c-toggle-switch__wrapper {
  width: auto;
}

.c-notifications-page .c-toggle-switch__wrapper .c-label {
  flex-direction: row;
}

.c-notifications-page .c-toggle-switch__wrapper .c-label__spacing {
  display: flex;
  margin: 0;
}

.c-notifications-page__section {
  margin-bottom: var(--g-spacing-2x-large);
}
