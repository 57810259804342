.c-community-menu {
}

.c-community-menu__list {
  align-items: center;
  display: flex;
  margin: 0;
  width: 100%;
}

.c-community-menu__item {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0;
  width: 33.333%;
}

.c-community-menu__link {
  color: inherit;
  font-size: var(--g-font-size-small);
  font-weight: 600;
  transition: 150ms ease-in-out;
}

.c-community-menu__link:hover:not(.c-community-menu__link--active) {
  color: var(--color-primary);
}

.c-community-menu__link--active {
  background-color: var(--color-primary);
  border-radius: 5px;
  padding: var(--g-spacing-2x-small) var(--g-spacing-x-small);
}
