.c-connections-page__container {
  max-width: var(--container-width-sm);
  margin: 0 auto;
  padding: var(--g-spacing-2x-large) 0;
  width: 90%;
}

.c-connections-page__section {
  margin-bottom: var(--g-spacing-2x-large);
}
