.c-reaction-toolbar__container {
  display: flex;
  position: relative;
}

.c-reaction-toolbar {
  align-items: stretch;
  position: absolute;
  top: 0;
  left: 50%;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0px 0px 20px #84848454;
  display: flex;
  transform: translate(-50%, -100%);
  width: max-content;
}

.c-reaction-toolbar button {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  padding: 0.35rem 0.65rem;
}

.c-reaction-toolbar button:hover {
  background-color: #f2f5f6;
}

.c-reaction-toolbar button img {
  width: var(--g-spacing-large);
}
