.c-social-header {
  background-color: var(--color-primary);
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9;
}

.c-social-header__container {
  align-items: center;
  color: var(--white);
  display: flex;
  margin: 0 auto;
  max-width: var(--container-width);
  padding: var(--spacing-4) 0;
  width: 90%;
}

.c-social-header__navigation {
  display: none;
}

.c-social-header__navigation ul {
  margin-left: 0;
}

.c-social-header .c-avatar {
  background-color: var(--color-accent) !important;
  margin-bottom: 0;
}

.c-social-header__logo {
  max-width: 14rem;
}
/* 
.c-social-header__avatar {
  display: none;
} */

.c-social-header__avatar--mobile {
  color: var(--g-color-white);
  cursor: pointer;
  display: block;
  margin-left: var(--g-spacing-small);
}

@media (min-width: 64em) {
  .c-social-header__navigation {
    display: flex;
    flex: 1;
    justify-content: center;
  }

  /* .c-social-header__avatar {
    display: block;
  } */

  .c-social-header__avatar--mobile {
    display: none;
  }
}
