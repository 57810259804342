.ek-section a {
  color: var(--green-900);
  font-weight: 700;
}

.ek-section h2,
.ek-section h3,
.ek-section h4,
.ek-section h5,
.ek-section h6,
.ek-section h4 *,
.ek-section h5 *,
.ek-section h6 * {
  color: var(--green-900);
  font-family: var(--font-body);
  font-weight: 800;
}

.ek-section h2 {
  font-size: 1.5rem;
}

.ek-section h3 {
  font-size: 1.3rem;
  margin-bottom: 0.85rem;
}

.ek-section h4 {
  font-size: 1.15rem;
  margin-bottom: 0.85rem;
}

.ek-section ul {
  list-style: disc;
}

.ek-section ol {
  list-style: decimal;
}

.ek-section li {
  letter-spacing: var(--ls-normal);
  margin-bottom: var(--spacing-2);
}

.ek-section .c-snippet:not(:last-of-type) {
  margin-bottom: 1rem;
}

.ek-text-snippet p:last-of-type {
  margin-bottom: 0;
}

@keyframes fadeUp {
  0% {
    opacity: 0;
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}