.c-new-messages-page__container {
  max-width: var(--container-width-sm);
  margin: 0 auto;
  padding: var(--g-spacing-2x-large) 0 0 0;
  width: 90%;
}

.c-new-messages-page__search {
  align-items: center;
  display: flex;
  margin-bottom: var(--g-spacing-2x-large);
}

.c-new-messages-page__container .c-list-card__message {
  margin-left: auto;
}

.c-new-messages-page__container .c-list-card__unread-count {
  align-items: center;
  background-color: var(--color-accent);
  border-radius: 50%;
  color: var(--g-color-white);
  display: flex;
  height: var(--g-font-size-large);
  font-size: 0.65rem;
  font-weight: 600;
  justify-content: center;
  width: var(--g-font-size-large);
}

.c-new-messages-page__actions {
  align-items: flex-end;
  display: flex;
  margin-bottom: var(--g-spacing-large);
}

.c-new-messages-page__actions .c-input-field {
  display: inline-block;
  width: auto;
}

.c-new-messages-page__actions .c-select-list {
  margin-bottom: 0;
  margin-right: var(--g-spacing-small);
}

.c-new-messages-page__filter {
  margin-left: auto;
}

.c-new-messages-page__filter .c-select-list {
  margin-right: 0;
}

.c-new-messages-page__actions select {
  padding-right: 2rem !important;
}

.c-new-messages-page__checkboxes {
  align-items: center;
  background-color: var(--g-color-white);
  border: 1px solid #ecedf0;
  border-radius: 10px;
  display: flex;
  margin-right: var(--g-spacing);
}

.c-new-messages-page__checkboxes .c-checkbox {
  margin-bottom: 0 !important;
  margin-top: 0 !important;
  padding: var(--g-spacing-small) var(--g-spacing);
}

.c-new-messages-page__checkboxes .c-label__text {
  white-space: nowrap;
}

.c-new-messages-page__checkboxes .c-checkbox .c-label {
  align-items: center;
}
