.c-image-preview {
  display: block;
  margin: 0 auto;
  position: relative;
}

.c-image-preview__image {
  max-height: 12rem;
}

.c-image-preview__button {
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  justify-content: center;
  padding: 0;
  position: absolute;
  right: 0;
  top: 0;
}

.c-image-preview__icon {
  background-color: var(--g-color-white);
  border-radius: 50%;
  box-shadow: 0px 0px 20px #84848454;
  height: var(--g-spacing-x-large);
  fill: #2b3332;
  stroke: var(--g-color-white);
  width: var(--g-spacing-x-large);
}
