.c-breadcrumbs {
  background-color: var(--white);
  margin-left: 0;
  padding: var(--g-spacing) 0;
}

.c-breadcrumb__list {
  display: flex;
  justify-content: flex-end;
  margin: 0 auto;
  max-width: var(--container-width);
  width: 90%;
}

.c-breadcrumb__item {
  align-items: center;
  display: inline-flex;
  font-family: var(--font-body);
  font-size: var(--g-font-size-small);
  font-weight: 500;
  line-height: 1;
  margin: 0;
}

.c-breadcrumb__item a {
  color: inherit;
  display: inline-flex;
}

.c-breadcrumb__divider {
  margin-bottom: 0;
  padding: 0 var(--g-spacing-2x-small);
}

.c-breadcrumb__home-icon {
  width: var(--g-spacing);
}
