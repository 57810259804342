.c-notification-item {
  align-items: center;
  background-color: var(--g-color-white);
  border-radius: 10px;
  color: var(--g-color-black);
  display: flex;
  margin-bottom: var(--g-spacing);
  padding: 0.5rem 0.85rem;
}

.c-notification-item__relate {
  align-items: center;
  display: flex;
  margin-right: var(--g-spacing-x-small);
}

.c-notification-item__relate img {
  width: var(--g-spacing);
}

.c-notification-item__message {
  margin-bottom: 0;
}

.c-notification-item__display-name {
  font-size: var(--g-font-size);
  font-weight: 600;
}

.c-notification-item__description {
  font-size: var(--g-font-size-small);
}

.c-notification-item__status {
  align-items: center;
  background-color: #f1f4f5;
  border-radius: 10px;
  color: #848484;
  display: flex;
  font-size: var(--g-font-size-small);
  margin-left: auto;
  padding: 0.2rem 0.6rem;
  margin-bottom: 0;
}

.c-notification-item__status--read {
  background-color: var(--color-primary);
  color: var(--g-color-white);
}

.c-notification-item__date {
  color: #bed1d8;
  font-size: var(--g-font-size-small);
  margin-bottom: 0;
  text-align: right;
  width: 9rem;
}
