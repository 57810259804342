.c-accept-decline {
  align-items: center;
  display: flex;
  margin-left: auto;
}

.c-accept-decline__button {
  align-items: center;
  cursor: pointer;
  display: flex;
  margin-left: var(--g-spacing-x-small);
  padding: 0;
}

.c-accept-decline__button--accept svg {
  stroke: var(--color-primary);
}

.c-accept-decline__button--decline svg {
  stroke: #f58a1f;
}

.c-accept-decline__button svg {
  height: var(--g-spacing-3x-large);
  width: var(--g-spacing-3x-large);
}
