.c-post-feelings-dialog__feelings {
  display: flex;
  flex-wrap: wrap;
  max-height: 22rem;
  overflow-y: auto;
}

.c-post-feelings-dialog .c-dialog__content {
  margin-top: var(--g-spacing-small);
}

.c-post-feelings-dialog__tabs {
  justify-content: center;
  margin-bottom: var(--g-spacing-x-small);
}

.c-post-feelings-dialog__tabs .c-radio-button__label {
  border: 1px solid #bed1d8 !important;
  background-color: #f1f4f5 !important;
}

.c-post-feelings-dialog__tabs input:checked + .c-radio-button__label {
  background-color: var(--color-primary) !important;
  border-color: var(--color-primary) !important;
}

.c-post-feelings-dialog__item {
  align-items: center;
  border: 2px solid transparent;
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  margin-bottom: var(--g-spacing-x-small);
  width: 50%;
}

.c-post-feelings-dialog__item--selected {
  border-color: var(--color-primary);
}

.c-post-feelings-dialog__emoji {
  font-size: var(--g-font-size-3x-large);
  margin-bottom: 0;
}

.c-post-feelings-dialog__name {
  font-size: var(--g-font-size-small);
  margin-bottom: 0;
}

@media (min-width: 64em) {
  .c-post-feelings-dialog__item {
    width: 20%;
  }
}
