.c-notifications {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin-left: auto;
  position: relative;
}

.c-notifications__icon {
  width: var(--g-spacing);
}

.c-notifications__count {
  align-items: center;
  background-color: var(--color-accent);
  border-radius: 50%;
  color: var(--g-color-white);
  display: flex;
  height: var(--g-font-size);
  font-size: 0.5rem;
  font-weight: 600;
  justify-content: center;
  position: absolute;
  right: 0;
  transform: translate(65%, -35%);
  top: 0;
  width: var(--g-font-size);
}
