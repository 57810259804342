.c-post-relate-icon-list {
  display: flex;
}

.c-post-relate-icon-list .c-relate-icon {
  background-color: var(--g-color-white);
  border: 2px solid var(--g-color-white);
  box-shadow: 0 1px 2px rgb(0 0 0 / 20%);
  border-radius: 50%;
  height: 1.25rem;
  width: 1.25rem;
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: center;
  font-size: 0.75rem;
  line-height: 1;
}

.c-post-relate-icon-list .c-relate-icon:not(:first-of-type) {
  margin-left: -4px;
}
