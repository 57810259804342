.react-joyride__tooltip button[aria-label='Next'],
.react-joyride__tooltip button[aria-label='Last'] {
  background-color: var(--g-color-brand-tertiary) !important;
}

.react-joyride__tooltip button[aria-label='Back'] {
  color: var(--g-color-brand-tertiary) !important;
}

.react-joyride__tooltip button[aria-label='Skip'] {
  color: var(--g-color-red-400) !important;
}
