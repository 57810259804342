.c-emoji-selector {
  bottom: var(--g-spacing);
  position: absolute;
  right: var(--g-spacing);
}

.c-emoji-selector button {
  align-items: center;
  cursor: pointer;
  display: flex;
  line-height: 1.3;
}

.c-emoji-selector button p {
  margin-bottom: 0;
}

.c-emoji-selector .c-dropdown-menu__dropdown {
  height: 10rem;
  overflow-y: auto;
  top: 0;
  transform: translateY(-100%);
  width: 16rem;
}

.c-emoji-selector__options {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -0.5rem;
  padding: var(--g-spacing-2x-small) var(--g-spacing);
}

.c-emoji-selector__emoji {
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0.5rem;
  width: 12%;
}

.c-emoji-selector__emoji button:hover {
  background-color: #f2f5f6;
  border-radius: 5px;
}
