.c-list-card {
  align-items: center;
  background-color: var(--g-color-white);
  display: flex;
  padding: var(--g-spacing-small);
}

.c-list-card:first-of-type {
  border-radius: 10px 10px 0 0;
}

.c-list-card:last-of-type {
  border-radius: 0 0 10px 10px;
}

.c-list-card:only-of-type {
  border-radius: 10px;
}

.c-list-card__header {
  align-items: center;
  display: flex;
  flex: 1;
  text-align: left;
}

.c-list-card .c-avatar {
  margin-bottom: 0;
  margin-right: var(--g-spacing-small);
}

.c-list-card__display-name {
  display: block;
  color: var(--g-color-black);
  font-weight: 700;
  line-height: 1.5;
  margin-bottom: 0;
}

.c-list-card__description {
  display: block;
  color: #848484;
  font-size: var(--g-font-size-x-small);
  line-height: 1.5;
  margin-bottom: 0;
}

.c-list-card__avatar--skeleton {
  border-radius: 50%;
  height: var(--g-spacing-2x-large);
  margin-right: var(--g-spacing);
  width: var(--g-spacing-2x-large);
}

.c-list-card__display-name--skeleton {
  height: var(--g-spacing-small);
  line-height: 0.5;
  margin-bottom: 0;
  width: var(--g-spacing-6x-large);
}

.c-list-card__description--skeleton {
  height: var(--g-spacing-small);
  width: var(--g-spacing-3x-large);
}

.c-list-card .c-avatar img {
  height: 100%;
  object-fit: cover;
}

.c-list-card__arrow {
  stroke: #dbe2e9;
  height: var(--g-spacing);
  margin-left: auto;
  width: var(--g-spacing);
}

.c-list-card .c-checkbox__icon-container {
  margin: 0;
}

.c-list-card__tag {
  flex: 1;
}

.c-list-card__tag span {
  background: #f1f4f5;
  color: #48756f;
  font-size: var(--g-font-size-small);
  margin-bottom: 0;
  border-radius: 6px;
  padding: var(--g-spacing-x-small) var(--g-spacing-small);
  margin-right: auto;
}
