.c-profile-details-dialog form {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.c-profile-details-dialog .h-spacing-small {
  margin-bottom: 0;
}

.c-profile-details-dialog .c-input__container {
  margin-bottom: var(--g-spacing);
}

.c-profile-details-dialog button {
  margin-left: auto;
  margin-top: var(--g-spacing-large);
}

.c-profile-details-dialog .c-toggle-switch__wrapper {
  margin-bottom: 2.5rem;
}
