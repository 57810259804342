.c-feed-page__container {
  max-width: var(--container-width-sm);
  margin: 0 auto;
  width: 90%;
}

.c-feed-page__search {
  align-items: stretch;
  display: flex;
  flex-direction: column;
  padding: var(--g-spacing-2x-large) 0 var(--g-spacing-large);
}

.c-feed-page__search .c-search-input {
  margin-right: var(--g-spacing);
}

.c-query-options {
  flex-direction: row;
}

.c-query-options .c-radio-button__label {
  background: var(--g-color-white);
  border: 1px solid transparent;
  border-radius: 10px;
  margin: 0 var(--g-spacing-small) 0 0;
  padding: var(--g-spacing-small) var(--g-spacing-large);
}

.c-query-options .c-label__text {
  margin: 0;
  text-transform: capitalize;
}

.c-query-options .c-radio-button__item:checked ~ .c-radio-button__label {
  background-color: var(--color-primary);
  color: var(--g-color-white);
}

.c-query-options .c-radio-button__item ~ label::before {
  display: none;
}

@media (min-width: 64em) {
  .c-feed-page__search {
    align-items: center;
    flex-direction: row;
  }
}
